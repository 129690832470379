@import url("https://use.typekit.net/hvu1sqm.css");
$red: #fec45d;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;
// $bannerBG: rgb(209, 47, 34);
$bannerBG: #950004;
// .site-content {
//   background: $black;
//   /* background: none; */
//   color: white;
// }

$sigFont: fairplex-narrow, sans-serif;
// $deco: spumante-regular-plus-shadow, sans-serif;
$deco: fairplex-narrow, serif;
.moores {
  font-family: $sigFont;
  img.nav-bar-logo {
    display: block;
    height: 50px;
    padding-left: 0.0em;
  }
  .cart.minimized {
    background-color: $bannerBG;
    background-attachment: fixed;
    background-size: cover;
    // padding-bottom: 0em;
    padding: 0.3em 0 0.1em 0.3em;
    border: none;
    /* background: black; */
    // border-bottom: 2px solid rgba(255,255,255,0.8);
  }
  .pages-nav {
    text-transform: uppercase;
    font-size: 1em;
    font-family: $sigFont;
    font-weight: 900;
    background: $bannerBG;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    text-decoration: none;
    color: white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid $bannerBG;
    &:hover, &.active {
      border-bottom: $borderWidth solid $red;
    }
  }
  .landing-page-wrapper {

    // background: #1d252c;
    background-color: #111;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23656565' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");

    h2, h3 {
      font-family: $deco;
      text-align: center;
      padding: 1em 0.2em;
      font-size: 1.3em;
      color: #ffffff;
    }

  }

  .landing-page, .top-banner {
    font-family: $deco;
    padding: 0.5em 0.3em;
    margin: 0;
    display: block;
    // min-height: 100%;
    min-height: 45vh;
    // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540691096/PablitosKitchen_Hero-1_FullSize_blog_960x540_sc2pmh.jpg);
    // background: url(https://afag.imgix.net/wild-carvery/all-natural-cheeseburger.jpg?w=1000&auto=format);

    background: url(https://afag.imgix.net/moores-delicatessen/hero.png?w=1200&auto=format) no-repeat;
    background-size: cover;
    background-position: center 10%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // justify-content: center;
    font-size: 1.5em;
    @media screen and (max-width: 600px) {
      background-image: url(https://afag.imgix.net/moores-delicatessen/mobile.jpg?w=800);
      background-position: center;
    }
    @media screen and (min-width: 1000px) {
      // background-image: url(https://afag.imgix.net/moores-delicatessen/mobile.jpg?w=800);
      background-color: #950004;
      background-size: contain;
    }

  }
  .landing-page .text {
    display: inline-block;
    // background: rgba(100,100,100,0.5);
    color: white;
    text-align: center;
    border-radius: 0.3em;
    text-shadow: 1px 1px 1px black;
    padding: 0.3em 0.4em;
    .large {
      font-size: 1.3em;
    }
  }
  .order-online-chownow-link {
    font-family: $sigFont;
    font-size: 0.5em;
    margin-top: 0.5em;
    display: inline-block;
    text-decoration: none;
    text-shadow: none;
    color: white;
    background: black;
    padding: 0.3em 0.5em;
    border-radius: 0.2em;
  }
  a.order-online-link {
    font-size: 1em;
    margin-top: 0.5em;
    display: inline-block;
    text-decoration: none;
    color: black;
    font-weight: 900;
    text-shadow: none;
    background: $red;
    // background: white;
    padding: 0.3em 0.5em 0.3em 0.5em;
    border-radius: 0.3em;
    border: 2px solid black;
    box-shadow: 1px 1px #000, 3px 3px #000, 5px 5px #000, 7px 7px #000;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .feature-section {
    min-height: 600px;
    background-size: cover;
    background-position: center;
    padding-top: 1em;
    &.summary {
      color: white;
      min-height: 0;
      text-align: center;
      padding-bottom: 2em;
      h2 {
        font-weight: 900;
        font-size: 2em;
      }
      .order-online-link {
        // border: 2px solid black;
        // box-shadow: 1px 1px #fff, 3px 3px #fff, 5px 5px #fff, 7px 7px #fff;
      }
    }
  }

  a.checkout-button.button {
    background: $red;
    color: black;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid black;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: $deco;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2em;
  background: none;
  color: black;
}
// .category-name .text::before {
//   content: "• "
// }
// .category-name .text::after {
//   content: " •"
// }
.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}



.bonus {
  font-family: Helvetica;
  font-style: italic;
  font-size: 0.5em;
  span {
    display: inline-block;
    background: #fec00b;
    border-radius: 0.3em;
    color: black;
    padding: 0.3em 0.5em;
  }
}

.ordering-header {
  background: rgba(0,0,0,0.5);
  padding: 0.3em 0.5em;
}

.ordering-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540691096/PablitosKitchen_Hero-1_FullSize_blog_960x540_sc2pmh.jpg);
  background-size: cover;
  background-position: center bottom;
  text-align: center;
  // font-family: "Chewy";
  color: white;
  height: 300px;
}

  .locations {
    display: flex;
    justify-content: center;
    background: #1d252c;
    font-family: $sigFont;
    .loc {
      padding: 2em 0.8em;
      max-width: 400px;
      color: white;
      text-align: center;

    }
  }

  .reviews-section {
    padding: 2em 0;
    color: black;
    background: white;
    h2 {
      color: black;
      font-family: $deco;
      font-weight: 700;
    }
    .reviews {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .a-review {
      max-width: 500px;
      blockquote {
        font-style: italic;
        line-height: 1.3em;
        // border-left: 4px solid #e10009;
        margin: 0.5em 0;
        padding-left: 0.5em;
        margin-left: 0.5em;
      }
    }
  }
  .more {
    text-align: center;
    a {
      text-decoration-color: white;
      display: inline-flex;
      border-radius: 0.3em;
      align-items: center;
      justify-content: center;
      background: rgba(150,150,150,0.5);
      overflow: hidden;
      margin-bottom: 1em;
    }
    h2 {
      padding: 0 0.5em;

    }
    img {
      // border-radius:
      width: 100px
    }
  }

  .page {
    max-width: 640px;
    margin: 2em auto;
    padding: 0 0.5em;
    line-height: 1.3em;
    h1 {
      font-size: 1.5em;
      font-weight: 600;
    }
    h2, h3, h4 {
      font-weight: 600;
    }
    p {
      margin: 1em 0;
    }
  }

  .page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    h1 {
      font-weight: 900;
      font-size: 2em;
      color: white;
      text-shadow: 1px 1px 2px black;
    }
  }

}
