.article {
  h2 {
    font-weight: 600;
    font-size: 1.3em;
  }
  padding: 1em;
  max-width: 720px;
  margin: 0 auto;
}
.form-wrapper {
  padding: 1em;
  max-width: 720px;
  margin: 0 auto;

  form {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @media only screen and (max-width: 480px) {
      grid-template-columns: auto;
    }
    // flex-wrap: wrap;
    // justify-content: center;
  }
  label, legend {
    // font-family: $sigFont;
    color: black;
  }
  legend {
    font-size: 1em;
  }
  fieldset {
    margin: 0.5em 0;
  }
  form > div {
    box-sizing: border-box;

    width: 100%;
    // margin: 0.5em;
  }
  label > span {
    // font-family: $sigFont;
    font-size: 1em;
  }
  form > .notes {
    grid-column-start: 1;
    grid-column-end: span 2;
    @media only screen and (max-width: 480px) {
      grid-column-end: 2;
    }
  }
  .submit-button {
    background: #c41a1a;
    color: white;
    // font-family: $sigFont;
    grid-column-start: 1;
    grid-column-end: span 2;

    @media only screen and (max-width: 480px) {
      grid-column-end: 2;
    }
  }
}
